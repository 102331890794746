import { getClassesFromOptionsMap } from '~/storyblok/utils/cssClassesHelpers'

export default function useMargin() {
  // https://v2.tailwindcss.com/docs/margin
  const marginTopOptions = {
    '0': 'mt-0',
    '1': 'mt-1',
    '2': 'mt-2',
    '3': 'mt-3',
    '4': 'mt-4',
    '5': 'mt-5',
    '6': 'mt-6',
    '7': 'mt-7',
    '8': 'mt-8',
    '9': 'mt-9',
    '10': 'mt-10',
    '11': 'mt-11',
    '12': 'mt-12',
    '13': 'mt-14',
    '14': 'mt-16',
    '15': 'mt-20',
    '16': 'mt-24',
    '17': 'mt-28',
    '18': 'mt-32',
    '19': 'mt-36',
    '20': 'mt-40',
    '21': 'mt-44',
    '22': 'mt-48',
    '23': 'mt-52',
    '24': 'mt-56',
    '25': 'mt-60',
    '26': 'mt-64',
    '27': 'mt-72',
    '28': 'mt-80',
    '29': 'mt-96',
  } as Record<string, string>

  const getMarginTopClasses = ({
    mobile,
    desktop,
    breakpoint = 'md',
  }: {
    mobile: any
    desktop: any
    breakpoint?: string
  }) => {
    return getClassesFromOptionsMap({
      classOptionsMap: marginTopOptions,
      mobile,
      desktop,
      breakpoint,
    })
  }
  return {
    getMarginTopClasses,
  }
}
