<template>
  <Intersect
    v-editable="blok"
    v-bind="{ ...(blok.id ? { id: blok.id } : {}) }"
    class="paragraph flex w-full flex-col pb-10"
    :class="[
      { 'px-4 pt-8': hasColor },
      getMarginTopClasses({
        mobile: blok.margin_top_mobile,
        desktop: blok.margin_top_desktop,
      }),
      {
        ...marginClasses,
        border: Object.keys(borderColor).length,
        'md:px-12':
          Object.keys(borderColor).length ||
          Object.keys(backgroundColor).length,
        'container max-w-6xl': blok.in_container,
      },
      ...alignment,
    ]"
    :style="{ ...backgroundColor, ...borderColor }"
    :threshold="0.5"
    @enter="intersectPromotionView"
  >
    <Headline v-if="headline" class="!items-start" v-bind="{ ...headline }">
      {{ headline.text }}
    </Headline>
    <div
      v-if="subline"
      class="mt-6 md:text-xl"
      :class="{
        'mb-4': !text && (links.length || (blok.images && blok.images.length)),
      }"
    >
      <RichTextRenderer :document="subline.body" />
    </div>
    <div class="flex h-full flex-col items-end">
      <div v-if="text" class="prose w-full max-w-none">
        <RichTextRenderer :document="text.body" />
      </div>
      <div
        v-for="link in links"
        :key="link._uid"
        class="mb-2 mt-auto w-full self-start"
      >
        <CmsButton
          :blok="link"
          :type="link.cta_variant || 'primary'"
          :no-padding="link.cta_variant === 'link-alternative'"
          :target="link.cta_target_blank ? '_blank' : '_self'"
          :data-testid="`button_${link._uid}`"
          @mousedown="clickObserver"
        >
          {{ link.cta_label }}
        </CmsButton>
      </div>
    </div>
    <div
      v-if="blok.images && blok.images.length"
      class="flex flex-row space-x-8"
    >
      <div
        v-for="(image, idx) in images"
        :key="`store-${idx}`"
        class="flex flex-row space-x-8"
      >
        <FimPicture
          class="cms-picture picture-contain"
          height="auto"
          provider="storyblok"
          :desktop="image.srcDesktop"
          :mobile="image.srcMobile"
          :alt="image.alt"
          :focus-desktop="image.focusDesktop"
          :focus-mobile="image.focusMobile"
        />
      </div>
    </div>
  </Intersect>
</template>

<script setup lang="ts">
import type { SbParagraph } from '../types/storyblok'

const props = defineProps({
  blok: {
    type: Object as PropType<SbParagraph>,
    default: () => {},
  },
})

const { marginClasses } = useStoryblokMargins(props.blok)
const { getMarginTopClasses } = useMargin()

const links = computed(
  () => props.blok.cta_content?.filter((c) => c.cta_label) || [],
)

const backgroundColor = computed(() => {
  const color = parseColor(props.blok.color_background)
  return color ? { 'background-color': color } : {}
})
const borderColor = computed(() => {
  const color = parseColor(props.blok.color_border)
  return color ? { 'border-color': color } : {}
})

const hasColor = computed(() => {
  const noColor = ['#ffffff', '#fff', 'white', 'transparent']
  const backgroundColor = parseColor(props.blok.color_background)
  const borderColor = parseColor(props.blok.color_border)

  return (
    (!backgroundColor || !noColor.includes(backgroundColor)) &&
    (!borderColor || !noColor.includes(borderColor))
  )
})

const headline = computed(() =>
  parseHeadlineRef(props.blok.headline_ref, {
    tag: HeadlineTag.H3,
    look: HeadlineLook.Custom,
  }),
)

const subline = computed(() => parseTextRef(props.blok.subline_ref))
const text = computed(() => parseTextRef(props.blok.text_ref))

const images = computed(
  () =>
    props.blok.images
      ?.map((ref) => parseImageRef(ref))
      ?.filter((ref): ref is NonNullable<ReturnType<typeof parseImageRef>> =>
        Boolean(ref),
      ) ?? [],
)

const alignment = computed(() => {
  const value = props.blok.text_placement

  switch (value) {
    case 'text-left':
      return [value, 'items-start']
    case 'text-center':
      return [value, 'items-center']
    case 'text-right':
      return [value, 'items-end']
    default:
      return [value]
  }
})

const { trackPromotionView, trackPromotionClick, trackButtonClick } =
  await useTrackingEvents()

const intersectPromotionView = (
  _: IntersectionObserverEntry,
  stop: () => void,
) => {
  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionView(props.blok)
  }
  stop()
}

const clickObserver = (event: Event) => {
  const element = event?.target as HTMLButtonElement
  trackButtonClick(element)

  if (isStoryblokTrackingContent(props.blok)) {
    trackPromotionClick(props.blok)
  }
}
</script>
